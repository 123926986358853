import React, {useEffect, useState} from "react";
import {Alert, Col, InputGroup, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import * as formik from 'formik';
import * as yup from 'yup';
import Button from "react-bootstrap/Button";
import {adminAuthApi} from "../../api/adminAuth.js";
import {useParams} from "react-router-dom";

export default function ResetPasswordPage() {
    const {Formik} = formik;
    const {resetKey} = useParams();
    const [resetSuccessful, setResetSucessful] = useState(false);
    const [resetKeyValid, setResetKeyValid] = useState(true);
    const schema = yup.object().shape({
        password: yup.string()
            .required("Password is required")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                "Password must be at least 8 characters, with an uppercase letter, lowercase letter, and a number."
            ),
        passwordConfirm: yup.string()
            .required("Password Confirm is required")
            .oneOf([yup.ref('password')], 'Passwords must match'),
    });

    useEffect(() => {
        const validateKey = async (key) => {
            const result = await adminAuthApi.validateResetKey(key);
            setResetKeyValid(result.validPasswordKey);
        }
        if (resetKey) {
            validateKey(resetKey);
        } else {
            setResetKeyValid(false);
        }

    }, [resetKey]);

    if (!resetKeyValid) {
        return (
        <>
            <h4 className="text-secondary">Reset Password</h4>
            <hr className="border-secondary mb-5"/>
            <Alert variant={"danger"}>Sorry the password reset link you used is either expired or invalid.  If you need to reset your password,
            click on the forgot password link on the login page and send yourself a new password reset link.</Alert>
            <Button className="btn-info w-100 mt-3" href={'/login'}>Go to Login</Button>
        </>
        );

    } else if (resetSuccessful){
        return (
            <>
                <h4 className="text-secondary">Reset Password</h4>
                <hr className="border-secondary mb-5"/>
                <p>Your password has been reset. You may now use your new password to login</p>
                <Button className="btn-info w-100 mt-3" href={'/login'}>Go to Login</Button>
            </>

        );
    } else {
        return (
            <div >
                <div className="text-center align-items-start mb-4">
                    <img
                        src="/src/images/BCTC_Logo_Horiz_small.png"
                        height="100"
                        className="d-inline-block align-top"

                    />
                </div>
                <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            setSubmitting(true);
                            await adminAuthApi.resetPassword(values.password, resetKey);
                            setResetSucessful(true);
                        } finally {
                            setSubmitting(false);
                        }

                    }}
                    initialValues={{
                        password: '',
                        passwordConfirm: '',
                    }}
                >
                    {({ handleSubmit, handleChange, touched, values, isSubmitting, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <h1>Reset Password</h1>
                            <p>Please enter your new password below.</p>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4" controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={touched.password && !!errors.password}
                                        />
                                        {touched.password && (<Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>)}
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="passwordConfirm">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="password"
                                            name="passwordConfirm"
                                            value={values.passwordConfirm}
                                            onChange={handleChange}
                                            isInvalid={touched.passwordConfirm && !!errors.passwordConfirm}
                                        />
                                        {touched.passwordConfirm && (<Form.Control.Feedback type="invalid">
                                            {errors.passwordConfirm}
                                        </Form.Control.Feedback>)}
                                    </InputGroup>
                                </Form.Group>
                            </Row>

                            <Button className="btn-info w-100 mt-3" type="submit" disabled={isSubmitting}>Reset My Password</Button>
                        </Form>
                    )}
                </Formik>
            </div>

        );
    }
}