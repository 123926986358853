import React, { useState, useEffect } from "react";
import { Alert, Col, Row, Button, Form, Dropdown } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { CSVLink } from "react-csv";
import { Typeahead } from "react-bootstrap-typeahead";
import {uniqWith, isEqual} from "lodash";
import DatePicker from "react-datepicker";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";

import { courseApi } from "../../../api/course.js";
import { clientApi } from "../../../api/client.js";
import { useAuth } from "../../../contexts/AuthContext.jsx";
import formatDate from "../../../_helpers/formatDate";

export default function CourseAttempts() {
    const authContext = useAuth();
    const isCustomerAdmin = authContext.authentication.role === "CustomerAdmin";
    const adminClientId = authContext.authentication.clientId;
    const adminClientName = authContext.authentication.clientName;
    const initialFilterState = {
        clientId: adminClientId || "",
        courseId: "",
        employeeNumber: "",
        name: "",
        passed: "both",
        startedAfter: null,
        startedBefore: null,
        completedAfter: null,
        completedBefore: null,
        otherFieldName: "",
        otherFieldValue: "",
    };

    const [pageState, setPageState] = useState({pageError: null}); 
    const [loading, setLoading] = useState(false);


    const [rowData, setRowData] = useState([]);
    const [clients, setClients] = useState([]);
    const [courses, setCourses] = useState([]);
    const [filters, setFilters] = useState(initialFilterState);
    const [otherFieldNames, setOtherFieldNames] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [showIncomplete, setShowIncomplete] = useState(false);
    const [showHidden, setShowHidden] = useState(false);
    const [filteredRowData, setFilteredRowData] = useState([]);
      
    const columnDefs = [
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 28,
        },
        { field: 'clientName', headerName: 'Client', sortable: true, flex: 3, resizable: true },
        { field: 'username', headerName: 'Group', sortable: true, flex: 2, resizable: true },
        { field: 'courseName', headerName: 'Course', sortable: true, flex: 4, resizable: true },
        { field: 'employeeNumber', headerName: 'Emp #', sortable: true, flex: 2, resizable: true },
        { field: 'firstName', headerName: 'First Name', sortable: true, flex: 2, resizable: true },
        { field: 'lastName', headerName: 'Last Name', sortable: true, flex: 2, resizable: true },
        // { field: 'score', headerName: 'Score', sortable: true, flex: 2, resizable: true },
        { field: 'passed', headerName: 'Pass', sortable: true, flex: 1, resizable: true },
        { field: 'otherFieldName1', headerName: 'Other Name 1', sortable: true, flex: 2, resizable: true },
        { field: 'otherFieldValue1', headerName: 'Other Value 1', sortable: true, flex: 2, resizable: true },
        // { field: 'started', headerName: 'Date Started', sortable: true, flex: 4, resizable: true, valueFormatter: params => params.value ? formatDate(params.value) : '' },
        { field: 'completed', headerName: 'Date Completed', sortable: true, flex: 3, resizable: true, valueFormatter: params => params.value ? formatDate(params.value) : '' },
        { field: 'hidden', headerName: 'Hidden', sortable: true, flex: 1, resizable: true },
    ];

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleDateChange = (name, date) => {
        setFilters({ ...filters, [name]: date });
        console.log(date);
    };

    const removeSuffix = (str) => {
        const match = str.match(/#(\d+)/);
        if (match) {
            return str.replace(match[0], '');
        } else {
            return str;
        }
    }

    const applyFilters = () => {

        console.log("Current filters:", filters); 
        setLoading(true);  // Start loading
        
        const { clientId, courseId, employeeNumber, name, passed, startedAfter, startedBefore, completedAfter, completedBefore, otherFieldName, otherFieldValue } = filters;
        const filterPayload = {};
    
        // Build filter payload based on filters
        if (clientId) filterPayload.clientId = parseInt(clientId);
        if (courseId) filterPayload.courseId = parseInt(courseId);
        if (employeeNumber) filterPayload.employeeNumber = employeeNumber;
        if (name) filterPayload.name = name;
        if (passed !== 'both') filterPayload.passed = passed === 'true';
        if (startedAfter) filterPayload.startedAfter = startedAfter.toISOString();
        if (startedBefore) filterPayload.startedBefore = startedBefore.toISOString();
        if (completedAfter) filterPayload.completedAfter = completedAfter.toISOString();
        if (completedBefore) filterPayload.completedBefore = completedBefore.toISOString();
        const cleanedOtherFieldName = removeSuffix(otherFieldName);
        if (otherFieldName) filterPayload.otherFieldName = cleanedOtherFieldName;
        if (otherFieldValue) filterPayload.otherFieldValue = otherFieldValue;
    
        courseApi.getCourseAttempts(filterPayload)
            .then((courseAttempts) => {
                setSelectedRows([]);
                setOtherFieldNames(getOtherFieldNames(courseAttempts));
                setRowData(courseAttempts);
            })
            .catch((error) => {
                setPageState(prevState => ({ ...prevState, pageError: 'Failed to load data' }));
            })
            .finally(() => {
                setLoading(false);  // Stop loading
            });        
    };

    const getOtherFieldNames = (courseAttempts) => {
        const otherFieldNames = [];
    
        courseAttempts.forEach(row => {
            for (let i = 1; i <= 10; i++) {
                const fieldName = row[`otherFieldName${i}`];
                if (fieldName) {
                    otherFieldNames.push({ field: `OtherField${i}`, fieldName });
                }
            }
        });
    
        // Use lodash to filter out non-unique objects
        const uniqueOtherFieldNames = uniqWith(otherFieldNames, isEqual);
    
        return uniqueOtherFieldNames;
    }

    const clearFilters = () => {
        setOtherFieldNames([]);
        setSelectedRows([]);
        setFilteredRowData([]);
        setFilters(initialFilterState);
        applyFilters();
    };

    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRows(selectedRows);
    };

    const handleOptionClick = async (operation) => {
        const selectedIds = selectedRows.map(row => row.id);

        if (selectedIds.length === 0) {
            setPageState(prevState => {return {...prevState, pageError: "Please select at least one row." }});
            return;
        }

        try {
            switch (operation) {
                case 'hide':
                    await courseApi.hideCourseAttempts(selectedIds);
                    break;
                case 'unhide':
                    await courseApi.unhideCourseAttempts(selectedIds);
                    break;
                case 'generateCertificates':
                    await courseApi.generateCertificates(selectedIds);
                    break;
                default:
                    console.error("Unknown operation:", operation);
            }

            // Refresh the grid data 
            applyFilters();
        } catch (error) {
            console.error("Operation failed:", error);
        }
    };

    // Run once to get client and course names
    useEffect(() => {
        if (!isCustomerAdmin) {            
        
            clientApi.getAllClients().then(setClients);
            courseApi.getAllCourses().then(setCourses);
        }

        setFilters(initialFilterState);
        applyFilters();
    }, []);

    // Run every time the data changes to filter hidden and incomplete
    useEffect(() => {
        const filteredData = rowData.filter(row => {
            const isIncomplete = !row.completed;
            const isHidden = row.hidden;
    
            return (showIncomplete || !isIncomplete) && (showHidden || !isHidden);
        });
    
        setFilteredRowData(filteredData);
    }, [rowData, showIncomplete, showHidden]);  



    return (
        <>
            <Row className="mt-3">
                <Col md={12}>
                    <h3 className="text-secondary">Course Attempts</h3>
                </Col>
            </Row>
            <hr className="mt-3 border-secondary" />
            <Row className="mt-3 ">
                <Col md={12} className="bg-light shadow p-4 rounded-3">
                    <Form>
                        <Row>
                            <Col md={5}>
                                <Form.Group controlId="filterClientId">
                                    <Form.Label>Client:</Form.Label><br />
                                    {!isCustomerAdmin ? (
                                        <Typeahead
                                            clearButton
                                            id="filterClientId"
                                            options={clients.map(client => client.clientName)}
                                            placeholder="All Clients"
                                            selected={
                                                filters.clientId
                                                    ? clients.filter(client => client.id === parseInt(filters.clientId)).map(client => client.clientName)
                                                    : []
                                            }
                                            onChange={(selected) => {
                                                const selectedClient = clients.find(client => client.clientName === selected[0]);
                                                handleFilterChange({ target: { name: 'clientId', value: selectedClient ? selectedClient.id : '' } });
                                            }}                                        
                                        />
                                    ) : (

                                        // we're faking a form control here to display the admin client name without adding it to the filter payload
                                        <div
                                            className="form-control-plaintext"
                                            style={{
                                                border: "1px solid #ced4da",
                                                borderRadius: ".25rem",
                                                padding: ".375rem .75rem",
                                                backgroundColor: "#e9ecef",
                                                color: "#495057",
                                            }}
                                        >
                                            {adminClientName}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={5}>
                                <Form.Group controlId="filterCourseId">
                                    <Form.Label>Course:</Form.Label><br />
                                    <Typeahead
                                        clearButton
                                        id="filterCourseId"
                                        options={
                                            isCustomerAdmin
                                            ? [...new Set(rowData.map(course => course.courseName))] // Use rowData for Customer Admins
                                            : courses.map(course => course.courseName) // Use full course list for Frame Admins
                                        }
                                        placeholder="All Courses"
                                        selected={
                                            filters.courseId
                                                ? (isCustomerAdmin
                                                    ? rowData.filter(course => course.id === parseInt(filters.courseId)).map(course => course.courseName)
                                                    : courses.filter(course => course.id === parseInt(filters.courseId)).map(course => course.courseName))
                                                : []
                                        }
                                        onChange={(selected) => {
                                            const selectedCourse = (isCustomerAdmin
                                                ? rowData.find(course => course.courseName === selected[0])
                                                : courses.find(course => course.courseName === selected[0])
                                            );
                                            handleFilterChange({ target: { name: 'courseId', value: selectedCourse ? selectedCourse.id : '' } });
                                        }}                                    
                                        disabled={isCustomerAdmin && rowData.length === 0} // Disable if Customer Admin and no rowData
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group controlId="filterPassed">
                                    <Form.Label>Passed:</Form.Label><br />
                                    <Form.Control as="select" name="passed" value={filters.passed} onChange={handleFilterChange}>
                                        <option value="both">Both</option>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={3}>
                                <Form.Group controlId="filterName">
                                    <Form.Label>Name</Form.Label><br />
                                    <Form.Control type="text" name="name" value={filters.name} onChange={handleFilterChange} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="filterEmployeeNumber">
                                    <Form.Label>Employee Number</Form.Label><br />
                                    <Form.Control type="text" name="employeeNumber" value={filters.employeeNumber} onChange={handleFilterChange} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="filterOtherFieldName">
                                    <Form.Label>Other Field Name:</Form.Label><br />
                                    <Form.Control 
                                        as="select" 
                                        name="otherFieldName" 
                                        value={filters.otherFieldName} 
                                        onChange={handleFilterChange}                                    
                                        disabled={otherFieldNames.length === 0}
                                    >
                                        <option value="">Select Field</option>
                                        {otherFieldNames.map((otherField, index) => (
                                            <option key={index} value={`${otherField.field}#${index}`}>{otherField.fieldName}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="filterOtherFieldValue">
                                    <Form.Label>Other Field Value:</Form.Label><br />
                                    <Form.Control 
                                        type="text" 
                                        name="otherFieldValue" 
                                        value={filters.otherFieldValue} 
                                        onChange={handleFilterChange}
                                    
                                        disabled={otherFieldNames.length === 0}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={3}>
                                <Form.Group controlId="filterStartedAfter">
                                    <Form.Label>Started After:</Form.Label><br />
                                    <i className="bi bi-calendar fs-4 me-3 text-secondary"></i>
                                    <DatePicker
                                        selected={filters.startedAfter}
                                        onChange={(date) => handleDateChange('startedAfter', date)}                                        
                                        showYearDropdown
                                        dateFormat="P"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="filterStartedBefore">
                                    <Form.Label>Started Before:</Form.Label><br />
                                    <i className="bi bi-calendar fs-4 me-3 text-secondary"></i>
                                    <DatePicker
                                        selected={filters.startedBefore}
                                        onChange={(date) => handleDateChange('startedBefore', date)}                                        
                                        showYearDropdown
                                        dateFormat="P"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="filterCompletedAfter">
                                    <Form.Label>Completed After:</Form.Label><br />
                                    <i className="bi bi-calendar fs-4 me-3 text-secondary"></i>
                                    <DatePicker
                                        selected={filters.completedAfter}
                                        onChange={(date) => handleDateChange('completedAfter', date)}                                        
                                        showYearDropdown
                                        dateFormat="P"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="filterCompletedBefore">
                                    <Form.Label>Completed Before:</Form.Label><br />
                                    <i className="bi bi-calendar fs-4 me-3 text-secondary"></i>
                                    <DatePicker
                                        selected={filters.completedBefore}
                                        onChange={(date) => handleDateChange('completedBefore', date)}                                        
                                        showYearDropdown
                                        dateFormat="P"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col md={6}> 
                                <Button variant="info" onClick={applyFilters} className="me-3">Apply Filter</Button>

                                <Button variant="info" onClick={clearFilters} className="me-3">Reset Filter</Button>
                                <CSVLink data={filteredRowData} filename="filtered_course_attempts.csv" className="btn btn-info" disabled={!filteredRowData || filteredRowData.length === 0}>Export CSV Report</CSVLink>
                            </Col>
                            <Col md={2}>
                                <Form.Group controlId="showIncomplete">
                                    <Form.Check 
                                        type="checkbox" 
                                        label="Show Incomplete" 
                                        checked={showIncomplete} 
                                        onChange={(e) => setShowIncomplete(e.target.checked)} 
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                {!isCustomerAdmin && (
                                    <Form.Group controlId="showHidden">
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Show Hidden" 
                                            checked={showHidden} 
                                            onChange={(e) => setShowHidden(e.target.checked)} 
                                        />
                                    </Form.Group>
                                )}
                            </Col>
                            <Col md={2} style={{ textAlign: "right" }}>
                                {isCustomerAdmin ? (
                                    <Button
                                    variant="info"
                                    onClick={() => handleOptionClick('generateCertificates')}
                                    disabled={!selectedRows || selectedRows.length === 0} 
                                    >
                                    Generate Certificates
                                    </Button>
                                ) : (
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            variant="info"
                                            id="dropdown-basic"
                                            disabled={!selectedRows || selectedRows.length === 0} 
                                        >
                                            Actions
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleOptionClick('hide')}>
                                            Hide Records
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleOptionClick('unhide')}>
                                            Unhide Records
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleOptionClick('generateCertificates')}>
                                            Generate Certificates
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={12}>
                </Col>
            </Row>
            {pageState.pageError && (
                <Alert key="pageError" variant="danger mt-3">
                    {pageState.pageError}
                </Alert>
            )}
            <Row className="mt-3 mx-2">
                <Col md={12}>
                    <div className="ag-theme-alpine" style={{ width: "100%" }}>
                        {loading ? (
                            <Alert key="loading" variant="info">Loading data, please wait...</Alert>
                        // ) : filtersAreEmpty() ? ( 
                        //     <Alert key="noFilters" variant="info">Please apply a filter to see course attempts.</Alert>
                        ) : (
                            <AgGridReact
                                rowData={filteredRowData}
                                columnDefs={columnDefs}
                                paginationPageSize={20}
                                pagination={true}
                                domLayout='autoHeight'
                                rowSelection="multiple"
                                onSelectionChanged={onSelectionChanged}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </>
    );
}

