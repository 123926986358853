import React, { useState, useCallback, useEffect } from "react";
import { Alert, Col, Row, Button, Form } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";

import { courseApi } from "../../../api/course.js";
import { clientApi } from "../../../api/client.js";
import { useAuth } from "../../../contexts/AuthContext.jsx";

export default function Feedback() {
    const authContext = useAuth();
    const isCustomerAdmin = authContext.authentication.role === "CustomerAdmin";
    const adminClientId = authContext.authentication.clientId;
    const adminClientName = authContext.authentication.clientName;

    const getCurrentDateAndMonthBefore = () => {
        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 1);
        return { fromDate, toDate };
    };

    const { fromDate, toDate } = getCurrentDateAndMonthBefore();

    const initialFilterState = {
        clientId: adminClientId || "",
        fromDate: fromDate,
        toDate: toDate
    };

    const [pageState, setPageState] = useState({pageError: null}); 
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [clients, setClients] = useState([]);
    const [filters, setFilters] = useState(initialFilterState);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleDateChange = (name, date) => {
        setFilters({ ...filters, [name]: date });
    };

    const applyFilters = () => {
        setLoading(true);  // Start loading
        
        const { clientId, fromDate, toDate } = filters;
        const filterPayload = {};
    
        if (clientId) filterPayload.clientId = parseInt(clientId);
        if (fromDate) filterPayload.fromDate = fromDate.toISOString();
        if (toDate) filterPayload.toDate = toDate.toISOString();
    
        courseApi.getFeedback(filterPayload)
            .then((feedback) => {
                const formattedData = feedback.map(client => {
                    return client.courses.map(course => {
                        const submissions = course.submissions;
                        const questions = submissions.length > 0 ? submissions[0].answers.map(ans => ans.question) : [];
                        const columnDefs = questions.map(q => ({
                            headerName: q,
                            field: q,
                            sortable: true,
                            filter: false,
                            flex: 4
                        }));
                        
                        const rowData = submissions.map(submission => {
                            const answers = submission.answers.reduce((acc, ans) => {
                                acc[ans.question] = ans.answer;
                                return acc;
                            }, {});
                            return { ...answers, submissionId: submission.submissionId };
                        });
                        
                        return {
                            courseTitle: client.name + ": " + course.name,
                            columnDefs,
                            rowData,
                            enablePagination: rowData.length > 10
                        };
                    });
                }).flat();

                setRowData(formattedData);
            })
            .catch((error) => {
                setPageState(prevState => ({ ...prevState, pageError: 'Failed to load data' }));
            })
            .finally(() => {
                setLoading(false);  // Stop loading
            });
    };

    useEffect(() => {
        if (!isCustomerAdmin) {            
            clientApi.getAllClients().then(setClients);
        }
    }, []);

    return (
        <>
            <Row className="mt-3">
                <Col md={12}>
                    <h3 className="text-secondary">Feedback</h3>
                </Col>
            </Row>
            <hr className="mt-3 border-secondary" />
            <Row className="mt-3 ">
                <Col md={12} className="bg-light shadow p-4 rounded-3">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="filterClientId">
                                    <Form.Label>Client:</Form.Label><br />
                                    {!isCustomerAdmin ? (
                                        <Typeahead
                                            clearButton
                                            id="filterClientId"
                                            options={clients.map(client => client.clientName)}
                                            placeholder="All Clients"
                                            selected={
                                                filters.clientId
                                                    ? clients.filter(client => client.id === parseInt(filters.clientId)).map(client => client.clientName)
                                                    : []
                                            }
                                            onChange={(selected) => {
                                                const selectedClient = clients.find(client => client.clientName === selected[0]);
                                                handleFilterChange({ target: { name: 'clientId', value: selectedClient ? selectedClient.id : '' } });
                                            }}                                        
                                        />
                                    ) : (
                                        <div
                                            className="form-control-plaintext"
                                            style={{
                                                border: "1px solid #ced4da",
                                                borderRadius: ".25rem",
                                                padding: ".375rem .75rem",
                                                backgroundColor: "#e9ecef",
                                                color: "#495057",
                                            }}
                                        >
                                            {adminClientName}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="fromDate">
                                    <Form.Label>Feedback from:</Form.Label><br />
                                    <i className="bi bi-calendar fs-4 me-3 text-secondary"></i>
                                    <DatePicker
                                        selected={filters.fromDate}
                                        onChange={(date) => handleDateChange('fromDate', date)}                                        
                                        showYearDropdown
                                        dateFormat="P"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="toDate">
                                    <Form.Label>Feedback to:</Form.Label><br />
                                    <i className="bi bi-calendar fs-4 me-3 text-secondary"></i>
                                    <DatePicker
                                        selected={filters.toDate}
                                        onChange={(date) => handleDateChange('toDate', date)}                                        
                                        showYearDropdown
                                        dateFormat="P"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col md={6}> 
                                <Button variant="info" onClick={applyFilters} className="me-3">Apply Filter</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            {pageState.pageError && (
                <Alert key="pageError" variant="danger mt-3">
                    {pageState.pageError}
                </Alert>
            )}
            <Row className="mt-3 mx-2 pt-5">
                {loading ? (
                    <Alert key="loading" variant="info">Loading data, please wait...</Alert>
                ) : rowData.length === 0 ? (
                    <Col md={12}>
                        <Alert key="noFilters" variant="info">No Results Found</Alert>
                    </Col>
                ) : (
                    rowData.map((courseData, index) => (
                        <Col md={12} key={index} className="mb-4">
                            <h4 className="text-secondary my-4">{courseData.courseTitle}</h4>
                            <div className="ag-theme-alpine">
                                <AgGridReact
                                    columnDefs={courseData.columnDefs}
                                    rowData={courseData.rowData}
                                    domLayout='autoHeight'
                                    pagination={courseData.enablePagination}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={[10, 20, 50, 100]}
                                />
                            </div>
                        </Col>
                    ))
                )}
            </Row>
        </>
    );
}
