import React, {useState} from 'react';
import {useLocation, useNavigate, Link} from "react-router-dom";
import {Alert, Form, Button} from 'react-bootstrap';

import * as formik from 'formik';
import * as yup from 'yup';

import {useAuth} from "../../contexts/AuthContext.jsx";

function LoginPage() {
    const { Formik } = formik;
    const schema = yup.object().shape({
        email: yup.string().required(),
        password: yup.string().required(),
    });

    const [pageState, setPageState] = useState({pageError: null});
    let navigate = useNavigate();
    let location = useLocation();
    let authContext = useAuth();
    let from = location.state?.from?.pathname || "/";
    console.log(from);

    return (
        <>
            <Formik
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        try {
                            await authContext.signIn(values.email, values.password)
                            navigate(from, { replace: true });
                        } catch (e){
                            setPageState(prevState => {return {...prevState, pageError: "Invalid username / password" }})
                            console.error("Invalid username / password" + e);
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={{
                    email: '',
                    password: '',
                }}
            >
                {({ handleSubmit, handleChange,touched, values, isSubmitting, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} className='w-100'>

                        <h4 className="text-secondary">Admin Login</h4>
                        <hr className="border-secondary mb-5"/>

                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                                type="email" placeholder="Enter username or email"
                                isInvalid={touched.email && !!errors.email}
                            />
                            {touched.email && (<Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>)}
                        </Form.Group>

                        <Form.Group
                            className="mb-1"
                            controlId="password"
                        >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                name="password"
                                onChange={handleChange}
                                value={values.password}
                                type="password"
                                placeholder="Password"
                                isInvalid={touched.password && !!errors.password}
                            />
                            {touched.password && (<Form.Control.Feedback type="invalid">
                                {errors.password}
                            </Form.Control.Feedback>)}
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="formBasicCheckbox"
                        >
                            <a className="small text-info" href="/forgotPassword">Forgot password?</a>
                        </Form.Group>

                        <Button className="btn-primary w-100 mt-3" type="submit" disabled={isSubmitting}>Login</Button>
                        {pageState.pageError && (
                            <Alert key="pageError" variant="danger mt-3">
                                {pageState.pageError}
                            </Alert>
                        )}
                    </Form>
                )}

            </Formik>
        </>
    );
}

export default LoginPage;
