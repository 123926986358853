import React, { useState } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function ActionBar({ id, name, onDelete, onEdit }) {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const authContext = useAuth();
    
    const handleDeleteConfirm = () => {
        onDelete(id);
        setShowDeleteModal(false);
    };

    const handleEdit = () => {
        onEdit(id);
    };

    const handleMasquerade = async (clientUserId) => {
        authContext.masqueradeAs(clientUserId);
    }

    return (
        <>
            <Container fluid className="d-flex justify-content-center">
                <Link onClick={handleEdit} className="bi bi-pencil me-3" style={{fontSize:18}}></Link>
                {authContext.authentication.role === 'Admin' || authContext.authentication.role === 'SuperUser' && 
                    <Link onClick={() => handleMasquerade(id)} className="bi bi-box-arrow-in-right me-3" style={{fontSize:18}}></Link>
                }
                {onDelete != 'hideDelete' && (
                    <>
                        <Link onClick={() => setShowDeleteModal(true)} className="bi bi-trash" style={{fontSize:18}}></Link>
                        <Modal 
                            show={showDeleteModal} 
                            onHide={() => setShowDeleteModal(false)}
                            centered
                            animation={false}
                        >
                            <Modal.Header className="bg-primary" closeButton>
                                <Modal.Title>Confirm Deletion</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="bg-light text-center py-5">Are you sure you want to remove "{name}"?</Modal.Body>
                            <Modal.Footer className="bg-light d-flex justify-content-center border-0">
                                <Button variant="info w-25 m-2" onClick={handleDeleteConfirm}>Delete</Button>
                                <Button variant="info w-25 m-2" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </Container>
        </>
    );
}